import React from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    contentText,
    image,
    contentButton,
    contentMainText,
    contentButtonText,
    button,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    secondWrapper,
} from './description-section.module.scss';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Wand from '../../../../assets/images/svg/wand_2.svg';
import ButtonWand from '../../../molecules/button-wand';

export interface DescriptionSectionProps {
    withTopPadding?: boolean;
    className?: string;
}

export default function DescriptionSection({ withTopPadding, className }: DescriptionSectionProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'survey');

    return (
        <SectionWrapper
            bgColor={'violet-dark'}
            className={`${main} ${className} ${withTopPadding ? topPadding : ''}`}
        >
            <div className={contentWrapper}>
                <div className={content}>
                    <div className={contentText}>
                        <div className={contentMainText}>
                            Miłej zabawy z modą życzy Emilia z całym zespołem Fashion Fairy
                        </div>
                        <div className={image}>
                            &nbsp;
                        </div>
                    </div>
                    <div className={contentButton}>
                        <ButtonWand text={t(aboutTranslations('button'))}></ButtonWand>
                        <div className={contentButtonText}>Kliknij "Wystylizuj mnie", zaufaj wróżce stylistce i cisze się darmową, spersonalizowaną modą już teraz</div>
                    </div>
                </div>
            </div>
        </SectionWrapper>
    );
}
