// extracted by mini-css-extract-plugin
export var button = "description-section-module--button--OiiYk";
export var content = "description-section-module--content--6b33y";
export var contentButton = "description-section-module--content-button--Q0dwC";
export var contentButtonText = "description-section-module--content-button-text--wDX8F";
export var contentMainText = "description-section-module--content-main-text--RBGh4";
export var contentText = "description-section-module--content-text--haUYG";
export var contentTextFull = "description-section-module--content-text-full--tKf0H";
export var contentWrapper = "description-section-module--content-wrapper--93RSB";
export var image = "description-section-module--image--MjSUo";
export var main = "description-section-module--main--fkBQZ";
export var topPadding = "description-section-module--top-padding--AvnYp";